import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Api from "../Api";
import {Helmet} from "react-helmet";
import {input, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {TableFooter} from "@mui/material";
import Loader from "../components/Loader";
import {toast} from "react-toastify";

const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'
let newArr

function Bitrix24(props) {
    const [res, setRes] = useState([]);
    const [sipNum, setSip] = useState([]);
    const [loading, setLoading] = useState(false);

    const userData = useSelector((state) => state.users.userData)
    const navigate = useNavigate()
    const [form, setForm] = useState({
        adminId: 0,
        key: '',
        startcall: '',
        domain: userData?.bitrix?.domain
    });
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const {data} = await Api.bitrix_get()
                const admin = await Api.bitrix_admin()

                if (!admin.data.admin.ADMIN) {
                    alert('Please log in as admin')
                    return
                }
                if (data.data.result) {
                    let arr = data.data.result.map(s => ({...s, sip: s.sip || ''}))
                    newArr = arr
                    setRes(arr)
                }
                let webhook = newArr.find(w => w.ID === admin.data.admin.ID)
                setForm(prevState => ({
                    ...prevState, key: webhook.key,  adminId: webhook.ID
                }))
                setLoading(false)
            } catch (e) {
                if(e.response.data.message === 'Application not found'){
                    navigate('/crm_integration#application_not_found')
                }else{
                    console.log(e.response.data.message)

                }
            }
        })()
    }, [])
    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getAllowed()
                setSip(data.inputNumber?.sip)
            } catch (e) {
            }
        })()
    }, []);
    const handleSipConnect = useCallback((item, index,) => {
        newArr = newArr.map((a, i) => {
            if (i === index) {
                a.sip = item
            }
            return a
        })
        setRes(newArr)

    }, [newArr])

    const handleCurrent = useCallback((item) => {
        setRes(newArr)
        setForm(prevState => ({
            ...prevState,
            startcall: item?.startcall || ''
        }))
    }, [newArr])
    const handleCorpChange = useCallback((index, corp) => {
        newArr = newArr.map((a, i) => {
            if (i === index) {
                a.corporation = +corp
            }
            return a
        })
        setRes(newArr)
    }, [newArr])
    const handleSaveRequest = useCallback(async (item, index) => {
        try {
            if (item?.sip) {
                if(!form.key){
                    toast.error('Please enter inbound webhook and save for all SIPs')
                    return
                }
                if (+item.crmId) {
                    await Api.bitrix_edit({
                        id: item.crmId,
                        key: form.key,
                        startcall: form.startcall,
                        adminID: form.adminId,
                        sip: item?.sip,
                        userID: item.ID,
                        corporation: item?.corporation || 0
                    })
                } else {
                    let {data} = await Api.bitrix_create({
                        key: form.key,
                        startcall: form.startcall,
                        adminID: form.adminId,
                        sip: item?.sip,
                        userID: item.ID,
                        corporation: item?.corporation
                    })
                    newArr = newArr.map((a, i) => {
                        if (i === index) {
                            a.crmId = data.crm.id
                        }
                        return a
                    })
                }
            } else {
                await Api.bitrix_delete_crm(item?.crmId)
                newArr = newArr.map((a, i) => {
                    if (i === index) {
                        a.crmId = 0
                    }
                    return a
                })
            }
            toast.success('Changes are made successfully')

        } catch (e) {

        }
    }, [form, newArr])

    const handleWebhook = useCallback((name, val) => {
        setForm((prev) => ({
            ...prev,
            [name]: val
        }))
    }, [])
    return (
        <>
            <Helmet>
                <title>
                    {sip.crm[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper className="webhook_block" sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <div>
                            <h4>Webhook</h4>

                            <div className="webhook">
                                <h6>Inbound webhook</h6>
                                <label className="search">
                                    <input value={form.key} onChange={(ev) => handleWebhook('key', ev.target.value)}/>
                                </label>
                            </div>
                            <div className="webhook">
                                <h6>OutBound webhook</h6>
                                <label className="search">
                                    <input value={form.startcall}
                                           onChange={(ev) => handleWebhook('startcall', ev.target.value)}/>
                                </label>
                            </div>
                        </div>
                    </Paper>

                </div>

                {loading ? <div className="loaderContainer"><Loader/></div> :
                    <div className="tableParent" id="tableParent">
                        <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                            <TableContainer
                            >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow className="sip_header">
                                            <TableCell sx={{fontSize: 20,}} align="left" colSpan={9}>
                                                Users of {userData?.bitrix?.domain}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="sip_num">
                                            <TableCell
                                                align='center'
                                            />
                                            <TableCell align='center'>
                                                ID
                                            </TableCell>
                                            <TableCell align='center'>
                                                Name
                                            </TableCell>

                                            <TableCell align='center'>
                                                Email
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                Type
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                Register date
                                            </TableCell>

                                            <TableCell
                                                align='center'
                                            >
                                                Selected SIP
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                Boxed version
                                            </TableCell>

                                            <TableCell
                                                align='center'
                                            >
                                                Action
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody id="dataBody">
                                        {res.length ? res.map((s, _) => (
                                            <TableRow key={_} hover style={{cursor:'pointer'}}  role="checkbox" tabIndex={-1}>
                                                <TableCell align="center" onClick={() => handleCurrent(s)}>
                                                    {s?.ACTIVE ? <CheckCircleIcon style={{color: color}}/> : null}
                                                </TableCell>
                                                <TableCell align="center" onClick={() => handleCurrent(s)}>
                                                    {s?.ID}
                                                </TableCell>
                                                <TableCell align="center" onClick={() => handleCurrent(s)}>
                                                    {s.NAME} {s.LAST_NAME}
                                                </TableCell>
                                                <TableCell align="center" onClick={() => handleCurrent(s)}>
                                                    {s.EMAIL}
                                                </TableCell>
                                                <TableCell align="center" onClick={() => handleCurrent(s)}>
                                                    {s.USER_TYPE}
                                                </TableCell>
                                                <TableCell align="center" onClick={() => handleCurrent(s)}>
                                                    {new Date(s.DATE_REGISTER).toDateString()}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <select value={s?.sip} className="crm_select"
                                                            onChange={(ev) => handleSipConnect(ev.target.value, _,)}>
                                                        <option
                                                            value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>

                                                        {sipNum?.map(s => (
                                                            <option key={s.number}
                                                                    value={s.number}>{s.number} {s.context || ''}</option>
                                                        ))}
                                                    </select>
                                                </TableCell>
                                                <TableCell>
                                                    <input type='checkbox' checked={+s?.corporation}
                                                           onChange={(ev) => handleCorpChange(_, +ev.target.checked)}/>
                                                </TableCell>
                                                <TableCell>
                                                    <button onClick={() => handleSaveRequest(s, _)} className="addBtn">
                                                        Save
                                                    </button>
                                                </TableCell>
                                            </TableRow>

                                        )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                            <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                                no data

                                            </TableCell>


                                        </TableRow>
                                        }
                                        {/*    );*/}
                                        {/*})}*/}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                                <div style={{margin:10}} className="crm_save_btn"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>}
            </Wrapper>
        </>

    );
}

export default Bitrix24;
