import React, {useCallback, useEffect, useRef, useState} from 'react';
import {history1, historyHeader, input, lang} from "../translate";
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-hy';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/i18n/datepicker-en-AU';
import Api from "../Api";
import {historyRequest} from "../store/actions/history";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import {REACT_APP_API_URL} from "../config";
import TuneIcon from '@mui/icons-material/Tune';
import $ from "jquery";
function HistoryFilter1(props) {
    const [filters, setFilters] = useState({
        startTime: new Date(),
        endTime: new Date(),
        number: '',
        status: '',
        disposition: '',
        line: '',
        direction: 'inner',
        period: 'this_week'
    })
    const location = useLocation();
    const dateInputStartRef = useRef(null);
    const dateInputEndRef = useRef(null);
    const profileData = useSelector(state => state.users.userData)
    const count = useSelector(state => state.history.count)
    const historyData = useSelector(state => state.history.historyData)
    const [isSeen, setIsSeen] = useState(false)
    const [filterBlock, setFilterBlock] = useState({
        title: [],
        desc: []
    })
    const navigate = useNavigate();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleChange = useCallback((name, ev) => {
        if (name === 'startTime' || name === 'endTime') {
            setFilters((prev) => ({
                ...prev,
                [name]: ev
            }));
            query[name] = ev
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setFilters((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
            query[name] = ev.target.value
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }

    }, [location.search])
    useEffect(() => {
        (async () => {
            let yourDate = new Date()
            const offset = yourDate.getTimezoneOffset()
            yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000))
            await dispatch(historyRequest(query.limit || 10, query.page || 1, {
                endTime: query.endTime || yourDate.toISOString().split('T')[0],
                startTime: query.startTime || yourDate.toISOString().split('T')[0],
                number: query.number || '',
                status: query.status || '',
                disposition: query.disposition || '',
                line: query.line || '',
                direction: query.direction || '',
                period: query.period || ''
            }))
        })()
    }, [location.search]);
    useEffect(() => {
        $.datepicker.setDefaults($.datepicker.regional[lang[+localStorage.getItem('atsLang')]]);

        $(dateInputEndRef.current).datepicker({
            dateFormat: 'yy-mm-dd', // Customize the date format
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
        });
        $(dateInputStartRef.current).datepicker({
            dateFormat: 'yy-mm-dd', // Customize the date format
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
        });

        $(dateInputStartRef.current).on('change', function () {
            handleChange('startTime', $(this).val());
        });
        $(dateInputEndRef.current).on('change', function () {
            handleChange('endTime', $(this).val());
        });
        $(dateInputStartRef.current).val(new Date().toISOString().split('T')[0])
        $(dateInputEndRef.current).val(new Date().toISOString().split('T')[0])
    }, [location.search]);

    const [select, setSelect] = useState(true)
    const [array, setArray] = useState(null)
    const dispatch = useDispatch()


    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault()
        setFilters({
            startTime: new Date(),
            endTime: new Date(),
            number: '',
            status: '',
            disposition: '',
            line: '',
            direction: 'inner',
            period: 'this_week'
        })
        setFilterBlock({
            title: [],
            desc: []
        })
        navigate(`?${qs.stringify({}, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        setIsSeen(false)
    }, [location.search])

    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getAllowed()
                setSelect(data.inputNumber)
            } catch (e) {
            }
        })()
    }, []);
    useEffect(() => {
        let resultObjectDis = {}
        let resultObjectLine = {}
        let resultObjectStatus = {}
        let resultObjectIvr = {}
        for (let i = 0; i < historyData?.facet_counts?.facet_fields?.status.length; i += 2) {
            const name = historyData?.facet_counts?.facet_fields?.status[i];
            resultObjectStatus[name] = historyData?.facet_counts?.facet_fields?.status[i + 1];
        }
        for (let i = 0; i < historyData?.facet_counts?.facet_fields?.ivr.length; i += 2) {
            const name = historyData?.facet_counts?.facet_fields?.ivr[i];
            resultObjectIvr[name] = historyData?.facet_counts?.facet_fields?.ivr[i + 1];
        }
        for (let i = 0; i < historyData?.facet_counts?.facet_fields?.line.length; i += 2) {
            const name = historyData?.facet_counts?.facet_fields?.line[i];
            resultObjectLine[name] = historyData?.facet_counts?.facet_fields?.line[i + 1];
        }
        for (let i = 0; i < historyData?.facet_counts?.facet_fields?.disposition.length; i += 2) {
            const name = historyData?.facet_counts?.facet_fields?.disposition[i];
            resultObjectDis[name] = historyData?.facet_counts?.facet_fields?.disposition[i + 1];
        }
        setArray({
            disposition: resultObjectDis,
            line: resultObjectLine,
            status: resultObjectStatus,
            ivr: resultObjectIvr
        })
    }, [historyData]);
    useEffect(() => {
        setFilterBlock({
            title: Object.keys(query),
            desc: Object.values(query)
        })
    }, [location.search])
    return (
        <div className="row" style={{margin: 2, alignItems: 'end'}}>
            <div data-v-725a497c="" className="history-menu">
                <div data-v-64d46774="" className="itl-tabs primary">
                    {history1.topBar.map(t => (
                        <div key={t.id} data-v-3ab865ea="" data-v-64d46774=""
                             className={query.direction === t.type ? "tab-item primary active" : "tab-item primary"}
                             data-qa={t.type}>
                            <label
                                data-v-3ab865ea="">
                                <input data-v-3ab865ea="" onClick={(ev) => handleChange('direction', ev)} type="radio"
                                       className="input"
                                       value={t.type}/>
                                <div data-v-3ab865ea="" className="tab">
                                    <div data-v-3ab865ea="" className="label">
                                        <div data-v-3ab865ea=""
                                             className="text"> {t.translate[+localStorage.getItem('atsLang')  || 1]}</div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
                <div data-v-bfdb1fa6="" className="itl-export-print">
                    <div data-v-bfdb1fa6="" className="itl-svg button-export-xls svg-xls-32">
                        <a href={REACT_APP_API_URL + `/history/exel?page=${query.page || 1}&userId=${profileData.id}&disposition=${query.disposition || ''}&line=${query.line || ''}&status=${query.status || ''}&endTime=${query.endTime || new Date().toISOString()}&startTime=${query.startTime || new Date().toISOString()}&number=${query.number || ''}&period=${query.period || ''}`}>

                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 style={{width: 32, height: 32, maxWidth: 32, maxHeight: 32,}}>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M17.5858 4C17.851 4 18.1054 4.10536 18.2929 4.29289L21.7071 7.70711C21.8946 7.89464 22 8.149 22 8.41421V15C22 15.5523 21.5523 16 21 16C20.4477 16 20 15.5523 20 15V9L17 6H7V26H10C10.5523 26 11 26.4477 11 27C11 27.5523 10.5523 28 10 28H7C5.89543 28 5 27.1046 5 26V6C5 4.89543 5.89543 4 7 4H17.5858ZM14 17C14.5523 17 15 17.4477 15 18C15 18.5523 14.5523 19 14 19H10C9.44772 19 9 18.5523 9 18C9 17.4477 9.44772 17 10 17H14ZM17 13C17.5523 13 18 13.4477 18 14C18 14.5523 17.5523 15 17 15H10C9.44772 15 9 14.5523 9 14C9 13.4477 9.44772 13 10 13H17ZM17 9C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H10C9.44772 11 9 10.5523 9 10C9 9.44772 9.44772 9 10 9H17Z"
                                      fill="currentColor"/>
                                <path
                                    d="M28.0804 22.7762C27.6442 22.1014 26.8135 21.8422 25.7529 21.8422C24.365 21.8422 23.1835 22.4845 23.1835 23.8151C23.1835 25.0654 23.8603 25.4108 25.4662 25.6173C26.2921 25.732 26.5788 25.9946 26.5788 26.2813C26.5788 26.5796 26.3494 26.7746 25.8447 26.7746C25.702 26.7746 25.5825 26.7602 25.4824 26.7322C25.0085 26.5997 24.632 26.0978 24.14 26.0978C23.5742 26.0978 23.0837 26.5993 23.3713 27.0865C23.7791 27.7775 24.6366 28.1396 25.8562 28.1396C27.5194 28.1396 28.5747 27.4972 28.5747 26.0749C28.5747 24.7901 27.7718 24.5347 26.1085 24.3282C25.3744 24.2365 25.0991 23.9413 25.0991 23.6545C25.0991 23.3792 25.3285 23.1842 25.7759 23.1842C25.8835 23.1842 25.9779 23.1945 26.0605 23.215C26.5081 23.3265 26.8764 23.7807 27.3377 23.7807C27.9086 23.7807 28.3904 23.2557 28.0804 22.7762Z"
                                    fill="currentColor"/>
                                <path
                                    d="M16.4698 23.5857L15.8271 22.4908C15.6496 22.1885 15.3253 22.0028 14.9748 22.0028C14.1956 22.0028 13.7229 22.8624 14.1402 23.5204L15.0474 24.9507L13.9328 26.6546C13.554 27.2337 13.9695 28.0019 14.6614 28.0019C14.9652 28.0019 15.2471 27.8436 15.4051 27.5841L16.2403 26.2125L17.0214 27.5226C17.1986 27.8198 17.5191 28.0019 17.8652 28.0019C18.6436 28.0019 19.1128 27.1399 18.6903 26.4862L17.5939 24.7901L18.5795 23.3386C18.9651 22.7707 18.5583 22.0028 17.8719 22.0028C17.5652 22.0028 17.2819 22.167 17.1296 22.4332L16.4698 23.5857Z"
                                    fill="currentColor"/>
                                <path
                                    d="M22 27.0019V19.9962C22 19.4439 21.5515 18.9962 20.9992 18.9962C20.4469 18.9962 19.9985 19.4439 19.9985 19.9962V27.0019C19.9985 27.5542 20.4469 28.0019 20.9992 28.0019C21.5515 28.0019 22 27.5542 22 27.0019Z"
                                    fill="currentColor"/>
                            </svg>
                        </a>
                    </div>


                </div>
            </div>
            <div data-v-4b1795d7="" data-v-725a497c="" className="history-toolbar">
                <div data-v-239d7f3e="" data-v-4b1795d7="" className="itl-toolbar">
                    <div data-v-239d7f3e=""
                         className="itl-toolbar-wrapper with-divider divider-target-toolbar-more divider-transitioned">
                        <div data-v-0558cee0="" data-v-4b1795d7="" className="itl-toolbar-item period"
                             data-v-239d7f3e="">
                            <div data-v-0558cee0="" className="header"><span data-v-0558cee0="">Период</span>
                            </div>
                            <label>
                                <div className="d-flex align-items-start" style={{margin: '10px 0 0'}}>
                                    <span style={{width:60}}>{historyHeader.start[+localStorage.getItem('atsLang')  || 1]}</span>
                                    <input
                                        className="filter__item"

                                        ref={dateInputStartRef}
                                        type="text"
                                        value={query.startTime}
                                    />
                                </div>
                            </label>
                            <label>
                                <div className="d-flex align-items-start" style={{margin: '10px 0 0'}}>
                                    <span style={{width:60}}>{historyHeader.end[+localStorage.getItem('atsLang')  || 1]}</span>
                                    <input
                                        className="filter__item"
                                        ref={dateInputEndRef}
                                        type="text"
                                        value={query.endTime}
                                    />
                                </div>
                            </label>
                        </div>
                        <div data-v-0558cee0="" data-v-4b1795d7="" className="itl-toolbar-item " data-v-239d7f3e="">
                            <div data-v-0558cee0="" className="header"><span data-v-0558cee0="">Тип звонка</span>
                            </div>
                            <div data-v-64d46774="" data-v-4b1795d7="" className="itl-tabs secondary mine"
                                 data-v-0558cee0="">
                                <div data-v-3ab865ea="" data-v-64d46774=""
                                     className={!query.status ? "tab-item secondary active" : "tab-item secondary"}
                                     data-qa="total" onClick={(ev) => handleChange('status', {target: {value: ''}})}>
                                    <label data-v-3ab865ea="">
                                        <input
                                            data-v-3ab865ea="" type="radio"
                                            className="input" value="total"/>
                                        <div data-v-3ab865ea="" className="tab fixed-width">
                                            <div data-v-3ab865ea="" className="label with-accessory-text">
                                                <div data-v-3ab865ea="" className="text"> Все</div>
                                            </div>
                                            <div data-v-3ab865ea="" className="accessory-text">
                                                <div data-v-3ab865ea="" className="text">{count}</div>
                                            </div>
                                        </div>
                                    </label></div>
                                {history1.callType.map(c => (
                                    <div key={c.id} data-v-3ab865ea="" data-v-64d46774=""
                                         className={query.status === c.value ? "tab-item secondary active" : "tab-item secondary"}
                                         data-qa={c.type}>
                                        <label data-v-3ab865ea=""><input onClick={(ev) => handleChange('status', ev)}
                                                                         data-v-3ab865ea="" type="radio"
                                                                         className="input"
                                                                         value={c.value}/>
                                            <div data-v-3ab865ea="" className="tab fixed-width">
                                                <div data-v-3ab865ea=""
                                                     className="itl-svg svg-call-outgoing-24 with-text"
                                                     style={{color: c.color}}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         style={{width: 24, height: 24, maxWidth: 24, maxHeight: 24}}>
                                                        <path
                                                            d={c.path}
                                                            fill="currentColor"/>
                                                    </svg>
                                                </div>
                                                <div data-v-3ab865ea="" className="label with-icon with-accessory-text">
                                                    <div data-v-3ab865ea=""
                                                         className="text"> {c.translate[+localStorage.getItem('atsLang')  || 1]}</div>
                                                </div>
                                                <div data-v-3ab865ea="" className="accessory-text">
                                                    <div data-v-3ab865ea=""
                                                         className="text">{array?.status[c.value] || 0}</div>
                                                </div>
                                            </div>
                                        </label></div>

                                ))}
                            </div>
                        </div>
                        <div data-v-0558cee0="" data-v-4b1795d7="" className="itl-toolbar-item filters-sidebar"
                             data-v-239d7f3e="">
                            <div data-v-34ae6e4d="" data-v-4b1795d7="" className="itl-sidebar-with-toggle"
                                 data-v-0558cee0="">
                                <button data-v-82c7c586="" data-v-34ae6e4d=""
                                        className="itl-button secondary itl-button-icon"
                                        onClick={() => setIsSeen(true)}>
                                    <TuneIcon />
                                    <span data-v-82c7c586="" className="button-text">Фильтры</span></button>
                            </div>
                        </div>
                        <div data-v-0558cee0="" data-v-239d7f3e="" className="itl-toolbar-item responsible-more"
                             style={{display: 'none'}}/>

                        <div data-v-239d7f3e="" className="right-side">
                            <div data-v-0558cee0="" data-v-4b1795d7="" className="itl-toolbar-item align-right"
                                 data-v-239d7f3e="">
                                <div data-v-06e5edcb="" data-v-4b1795d7="" className="itl-search"
                                     data-v-0558cee0="">
                                    <div data-v-06e5edcb="" className="wrapper">
                                        <div data-v-2993ab1c="" data-v-06e5edcb="" data-qa="itl-combobox"
                                             className="itl-combobox-new">
                                            <div data-v-2993ab1c="" className="wrapper without-arrow">
                                                <div data-v-2993ab1c="" className="input">
                                                    <input data-v-2993ab1c=""
                                                           type="text"
                                                           value={filters.number || query.number}
                                                           placeholder=""
                                                           onChange={(ev) => handleChange('number', ev)}
                                                           maxLength="100"
                                                           className="search placeholder searchable"/>
                                                </div>
                                                <div data-v-2993ab1c="" id="items-6f660b23d9bb44ae9afac8dbaef2c29d"
                                                     className="items position-below hidden"
                                                     style={{maxHeight: 240, display: 'none'}}/>
                                            </div>

                                            <div data-v-2993ab1c="" className="text text-hint"
                                                 style={{display: 'none'}}/>
                                        </div>
                                        <div data-v-06e5edcb="">
                                            <div data-v-06e5edcb=""
                                                 className="itl-svg search-icon svg-search-24 empty-search">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     style={{width: 24, height: 24, maxWidth: 24, maxHeight: 24}}>
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M15.32 17.734a8 8 0 1 1 1.54-1.288l3.847 3.847a1 1 0 0 1-1.414 1.414l-3.973-3.973zM17 11a6 6 0 1 1-12 0 6 6 0 0 1 12 0z"
                                                          fill="currentColor"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div data-v-06e5edcb="" style={{display: 'none'}}>
                                            <div data-v-06e5edcb="" className="itl-svg search-icon svg-close-24">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     style={{width: 24, height: 24, maxWidth: 24, maxHeight: 24}}>
                                                    <path
                                                        d="M5.302 5.302c-.4.4-.397 1.053.004 1.453l5.25 5.25-5.258 5.258c-.4.4-.396 1.046.004 1.446.4.4 1.046.404 1.446.004l5.258-5.258 5.257 5.258c.4.4 1.046.396 1.446-.004.4-.4.404-1.046.004-1.446l-5.257-5.257 5.257-5.257c.4-.4.396-1.046-.004-1.447a1.02 1.02 0 0 0-1.446-.003l-5.257 5.257-5.257-5.258a1.02 1.02 0 0 0-1.447.004z"
                                                        fill="currentColor"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row">
                {filterBlock.title.map((l, index) => (
                    <div key={index} className="filter__item">
                        <span>{l} : {filterBlock.desc[index]}</span>
                        {l !== 'endTime' && l !=='startTime'?<span onClick={(ev) => handleChange(l, {target:{value:''}})} style={{cursor:'pointer'}}><b>x</b></span>:null}
                    </div>
                ))}
                {filterBlock.title.length?<span className="filter__item clean_filter" onClick={handleSubmit}>Clear the filters</span>:null}
            </div>

            <div onClick={() => setIsSeen(false)}
                 className={isSeen ? 'seen position-fixed asideHeaderBack' : 'position-fixed asideHeaderBack'}/>
            <div className={isSeen ? 'seen position-fixed asideHeader' : 'position-fixed asideHeader'}>
                <h4 className="d-flex justify-content-between"><span>Filters</span> <span onClick={() => setIsSeen(false)} style={{cursor:'pointer'}}><b>x</b></span></h4>

                <div className="d-flex flex-column align-items-start" style={{margin: '10px 0', fontSize: 16}}>
                    <span>{historyHeader.calls[+localStorage.getItem('atsLang')  || 1]}</span>
                    <select value={query.status || ''} onChange={(e) => handleChange('status', e)}>
                        <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                        <option value="Incoming Call" id="1">Incoming
                            Call {filters.status && filters.status !== 'Incoming Call' ? '' : array?.status['Incoming Call'] ? array.status['Incoming Call'] : 0}</option>
                        <option value="Out Call" id="2">Out
                            Call {filters.status && filters.status !== 'Out Call' ? '' : array?.status['Out Call'] ? array.status['Out Call'] : 0}</option>
                        <option value="Local Call" id="3">Local
                            Call {filters.status && filters.status !== 'Local Call' ? '' : array?.status['Local Call'] ? array.status['Local Call'] : 0}</option>
                        <option value="Redirect Call" id="4">Redirect
                            Call {filters.status && filters.status !== 'Redirect Call' ? '' : array?.status['Redirect Call'] ? array.status['Redirect Call'] : 0}</option>
                    </select>
                </div>
                <div className="d-flex flex-column align-items-start" style={{margin: '10px 0', fontSize: 16}}>
                    <span>{historyHeader.virtual[+localStorage.getItem('atsLang')  || 1]}</span>
                    <select value={query.number || ''} onChange={ev => handleChange('number', ev)}>
                        <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                        {select?.provider?.map(s => (
                            <option key={s.number} value={s.number}>{s.number} {s.name || ''}</option>
                        ))}
                        {select?.fix_provider?.map(s => (
                            <option key={s.number} value={s.number}>{s.number} {s.name || ''}</option>
                        ))}
                    </select>
                </div>
                <div className="d-flex flex-column align-items-start" style={{margin: '10px 0', fontSize: 16}}>
                    <span>{historyHeader.status[+localStorage.getItem('atsLang')  || 1]}</span>
                    <select value={query.disposition || ''}
                            onChange={ev => handleChange('disposition', ev)}>
                        <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                        <option
                            value='ANSWERED'>ANSWERED {filters.disposition && filters.disposition !== 'ANSWERED' ? '' : array?.disposition?.ANSWERED}</option>
                        <option value="NO ANSWER">NO
                            ANSWER {filters.disposition && filters.disposition !== 'NO ANSWER' ? '' : array ? array.disposition['NO ANSWER'] : 0}</option>
                        <option
                            value="BUSY">BUSY {filters.disposition && filters.disposition !== 'BUSY' ? '' : array?.disposition?.BUSY || 0}</option>
                        <option
                            value="FAILED">FAILED {filters.disposition && filters.disposition !== 'FAILED' ? '' : array?.disposition?.FAILED || 0}</option>
                    </select>
                </div>
                <div className="d-flex flex-column align-items-start" style={{margin: '10px 0', fontSize: 16}}>
                    <span>{historyHeader.call_stop[+localStorage.getItem('atsLang')  || 1]}</span>
                    <select value={query.line || ''} onChange={ev => handleChange('line', ev)}>
                        <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                        <option
                            value="IVR">IVR {filters.line && filters.line !== 'IVR' ? '' : array?.ivr?.IVR || 0}</option>
                        <option
                            value="musicHold">MusicHold {filters.line && filters.line !== 'musicHold' ? '' : array?.line?.musicHold || 0}</option>
                    </select>
                </div>
                <div className="d-flex flex-column align-items-start" style={{margin: '10px 0', fontSize: 16}}>
                    <span>{historyHeader.sip[+localStorage.getItem('atsLang')  || 1]}</span>
                    <select value={query.number || ''} onChange={(ev) => handleChange('number', ev)}>
                        <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                        {select?.sip?.map(s => (
                            <option key={s.number} value={s.number}>{s.number} {s.context || ''}</option>
                        ))}
                    </select>
                </div>
                <div className="d-flex flex-column align-items-start" style={{margin: '10px 0', fontSize: 16}}>
                    <span>{historyHeader.included[+localStorage.getItem('atsLang')  || 1]}</span>
                    <select value={query.number || ''} onChange={(ev) => handleChange('number', ev)}>
                        <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                        {select?.allowed?.map(s => (
                            <option key={s.number} value={s.number}>{s.number} {s.context || ''}</option>
                        ))}
                    </select>
                </div>
                <span className="filter__item clean_filter" style={!filterBlock.title.length?{color:'#ccc'}:{}} onClick={handleSubmit}>Clear the filters</span>

            </div>
        </div>
    );
}

export default HistoryFilter1;
