import { combineReducers } from 'redux';
import forward from './forward';
import crm from './crm';
import sip from './sip';
import shortNumber from './shortNumber';
import ivr from './ivr';
import history from './history';
import included from './included';
import did from './did';
import callRout from './callRout';
import autoCall from './autoCall';
import file from './file';
import blocked from './blocked';
import ip from './ip';
import users from './users';

export default combineReducers(
  {
      users,forward, crm, sip, shortNumber, ivr,history,included,did,callRout,autoCall,file,blocked,ip
  },
);
