import React, {useCallback, useEffect, useState} from 'react';
import {auto, global, input, short, sip, weekDays} from "../translate";
import Api from "../Api";

function AutoCallBlock({form,setForm}) {
    const [graphic, setGraphic] = useState({
        num: '',
        weekStart: '',
        weekEnd: '',
        timeStart: '10:00',
        timeEnd: '23:59',
        attempt: 2,
        okStart: 0,
        okEnd: 60,
        phone: ''
    })
    const [inAuto,setIn] = useState([])

    const handleAddGraphic = useCallback(async () => {
        setForm((prev) => ({
            ...prev,
            time: [...prev.time, {...graphic, id: new Date()}]
        }))
    }, [graphic])
    const handleDelete = useCallback(async (id) => {
        setForm((prev) => ({
            ...prev,
            time: prev.time.filter(t => t.id !== id)
        }))
    }, [graphic])
    const handleTimeChange = useCallback((name, ev) => {
        setGraphic((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    useEffect(() => {
        (async () =>{
            try {
                const {data} = await Api.getInAuto()
                setIn(data.inputNumber.buddies)
            } catch (e) {

            }
        })()
    }, [])
    return (
        <div className="short_block" style={{margin: '30px 0',}}>
            <div className="d-flex flex-row text-center" style={{width: 'fit-content'}}>
                <div style={{width: 200}}>
                    {auto.num[+localStorage.getItem('atsLang')  || 1 ]}
                </div>
                <div style={{width: 200}}>
                    {short.week[+localStorage.getItem('atsLang')  || 1 ]}
                </div>
                <div style={{width: 200}}>
                    {short.time[+localStorage.getItem('atsLang')  || 1 ]}
                </div>
                <div style={{width: 200}}>
                    {auto.ok[+localStorage.getItem('atsLang')  || 1 ]}
                </div>
                <div style={{width: 200}}>
                    {auto.phone[+localStorage.getItem('atsLang')  || 1 ]}
                </div>
                <div style={{width: 200}}>
                    {auto.attempt[+localStorage.getItem('atsLang')  || 1 ]}
                </div>
                <div style={{width: 200}}>
                    {sip.action[+localStorage.getItem('atsLang')  || 1 ]}
                </div>
            </div>
            {form.time.map(s => (
                <div key={s.id} className="d-flex flex-row text-center"
                     style={{width: 'fit-content', margin: '10px 0'}}>
                    <div style={{width: 200}}>
                                   <span>
                                       {s.num}
                                   </span>
                    </div>
                    <div style={{width: 200}}>
                                    <span style={{width: '40%', height: 40, display: 'inline-block'}}>
                                       {s.weekStart}
                                   </span>
                        <span>-</span>
                        <span style={{width: '40%', height: 40, display: 'inline-block'}}>
                                        {s.weekEnd}
                                    </span>
                    </div>
                    <div style={{width: 200}}>
                                    <span style={{width: '40%', height: 40}}>
                                        {s.timeStart}
                                    </span>
                        <span>-</span>
                        <span style={{width: '40%', height: 40}}>
                                        {s.timeEnd}
                                    </span>
                    </div>
                    <div style={{width: 200}}>
                                    <span style={{width: '40%', height: 40, display: 'inline-block'}}>
                                       {s.okStart}
                                   </span>
                        <span>-</span>
                        <span style={{width: '40%', height: 40, display: 'inline-block'}}>
                                        {s.okEnd}
                                    </span>
                    </div>

                    <div style={{width: 200}}>
                                   <span>
                                       {s.phone}
                                   </span>
                    </div>
                    <div style={{width: 200}}>
                                   <span>
                                       {s.attempt}
                                   </span>
                    </div>
                    <div style={{width: 200}} className="d-flex justify-content-center">
                        <button onClick={() => handleDelete(s.id)} className="deleteBtn"
                                style={{background: 'red'}}>
                            {global.delete[+localStorage.getItem('atsLang')  || 1 ]}
                        </button>
                    </div>

                </div>
            ))}
            <div className="d-flex flex-row text-center" style={{width: 'fit-content', margin: '10px 0'}}>

                <div style={{width: 200}}>
                    <select value={graphic.num} onChange={(ev) => handleTimeChange('num', ev.target.value)}
                            style={{width: '100%', height: 40}}>
                        <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                        {inAuto.map(k => (
                            <option value={k.name}>{k.title}</option>
                        ))}
                    </select>
                </div>
                <div style={{width: 200}}>
                    <select value={graphic.weekStart}
                            onChange={(ev) => handleTimeChange('weekStart', ev.target.value)}
                            style={{width: '40%', height: 40}}>
                        <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                        {weekDays.map(k => (
                            <option value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                        ))}
                    </select>
                    <span>-</span>
                    <select value={graphic.weekEnd}
                            onChange={(ev) => handleTimeChange('weekEnd', ev.target.value)}
                            style={{width: '40%', height: 40}}>
                        <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                        {weekDays.map(k => (
                            <option value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                        ))}
                    </select>
                </div>
                <div style={{width: 200}}>
                    <input onChange={(ev) => handleTimeChange('timeStart', ev.target.value)}
                           value={graphic.timeStart} style={{width: '40%', height: 40}} type="time"/>
                    <span>-</span>
                    <input onChange={(ev) => handleTimeChange('timeEnd', ev.target.value)}
                           value={graphic.timeEnd} style={{width: '40%', height: 40}} type="time"/>
                </div>
                <div style={{width: 200}}>
                    <input
                        className="ok"
                        value={graphic.okStart}
                        onChange={(ev) => handleTimeChange('okStart', ev.target.value)}
                        style={{width: '40%', height: 40}}/>
                    <span>-</span>
                    <input
                        className="ok"
                        value={graphic.okEnd}
                        onChange={(ev) => handleTimeChange('okEnd', ev.target.value)}
                        style={{width: '40%', height: 40}}/>
                </div>
                <div style={{width: 200}}>
                    <input className="ok"
                           onChange={(ev) => handleTimeChange('phone', ev.target.value)}
                           value={graphic.phone} style={{width: '90%', height: 40}}/>
                </div>
                <div style={{width: 200}}>
                    <input className="ok"
                           onChange={(ev) => handleTimeChange('attempt', ev.target.value)}
                           value={graphic.attempt} style={{width: '90%', height: 40}}/>
                </div>

                <div style={{width: 200}} className="d-flex justify-content-center">
                    <button onClick={handleAddGraphic} className="addBtn">
                        {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                    </button>
                </div>

            </div>
        </div>
    );
}

export default AutoCallBlock;
