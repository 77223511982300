import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {included, global, short, input, sip, weekDays, menu} from "../translate";
import {useDispatch} from "react-redux";
import {shortNumber, shortNumberCreate} from "../store/actions/shortNumber";
import Api from "../Api";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import CloseIcon from '@mui/icons-material/Close';
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensures portal menu is above modal
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 700 ? 700 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight:'97vh'
};

function AddShort({open, setOpen}) {
    const [form, setForm] = useState({
        name: '',
        virtual: '',
        no_answer: '',
        sip: []
    })
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [sipData,setSip] = useState([])

    const [graphic, setGraphic] = useState({
        num: '',
        notes: '',
        weekStart: weekDays[1][+localStorage.getItem('atsLang')  || 1 ],
        weekEnd: weekDays[0][+localStorage.getItem('atsLang')  || 1 ],
        timeStart: '00:00',
        timeEnd: '23:59',
    })
    useEffect(() => {
        (async () => {
          try {
              const {data} = await Api.shortSip()
              setSip(data.sip)
          }catch (e) {

          }
        })()
    },[])

    const dispatch = useDispatch()

    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleTimeChange = useCallback((name, ev,notes) => {
        if(name === 'timeStart' || name === 'timeEnd'){
            const formattedTime = ev.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                second: undefined,
            });
            setGraphic((prevState) => ({
                ...prevState,
                [name]: formattedTime,
            }))
        } else if(name === 'num'){
            setGraphic((prevState) => ({
                ...prevState,
                [name]: ev,
                notes: notes || ''
            }))
        }else{
            setGraphic((prevState) => ({
                ...prevState,
                [name]: ev,
            }))
        }
    }, [])
    const handleAdd = useCallback(async () => {
        await dispatch(shortNumberCreate(query.limit || 10,query.page || 1,query.search || '',form,query.sortBy || '', query.sortOrder || ''))
        setOpen(false)
    }, [form,location.search])
    const handleAddGraphic = useCallback(async () => {
        setForm((prev) => ({
            ...prev,
            sip: [...prev.sip, {...graphic,id:new Date()}]
        }))
    }, [graphic])
    const handleDelete = useCallback(async (id) => {
        setForm((prev) => ({
            ...prev,
            sip: prev.sip.filter(t => t.id !== id)
        }))
    }, [graphic])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {menu.shortC[+localStorage.getItem('atsLang')  || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </span>
                    </div>
                    <div className="row" style={{margin: '30px 0'}}>
                        <label style={{margin:'20px 0'}} className="col-md-6 count">
                            <span>
                               {sip.note[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                            <input value={form.name} onChange={(ev) => handleChange('name', ev.target.value)}/>
                        </label>
                        <label style={{margin:'20px 0'}} className="col-md-6 count">
                            <span>
                               {short.short[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                            <input value={form.virtual}
                                   onChange={(ev) => handleChange('virtual', ev.target.value)}/>
                        </label>
                        <label style={{margin:'20px 0'}} className="col-md-12 count">
                            <span>
                               {short.no_answer[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                            <input value={form.no_answer}
                                   onChange={(ev) => handleChange('no_answer', ev.target.value)}/>
                        </label>
                    </div>
                    <div className="short_block" style={{margin: '30px 0',}}>
                        <div className="d-flex flex-row text-center" style={{width: 'fit-content'}}>
                            <div style={{width: 170}}>
                                {short.private[+localStorage.getItem('atsLang')  || 1 ]}
                            </div>
                            <div style={{width: 150}}>
                                {short.week[+localStorage.getItem('atsLang')  || 1 ]}
                            </div>
                            <div style={{width: 150}}>
                                {short.time[+localStorage.getItem('atsLang')  || 1 ]}
                            </div>
                            <div style={{width: 150}}>
                                {sip.action[+localStorage.getItem('atsLang')  || 1 ]}
                            </div>
                        </div>
                        {form.sip.map(s => (
                            <div key={s.id} className="d-flex flex-row text-center" style={{width: 'fit-content',margin:'10px 0'}}>
                                <div style={{width: 170}}>
                                   <span>
                                       {s.num} {s.num.includes('(')?'()':`(${s.notes})`}
                                   </span>
                                </div>
                                <div style={{width: 150}}>
                                    <span style={{width: '40%', height: 40,textAlign:"center",display:'inline-block'}}>
                                       {s.weekStart}
                                   </span>
                                    <span>-</span>
                                    <span style={{width: '40%',textAlign:"center",  height: 40,display:'inline-block'}}>
                                        {s.weekEnd}
                                    </span>
                                </div>
                                <div style={{width: 150}}>
                                    <span style={{width: '40%', height: 40}} >
                                        {s.timeStart}
                                    </span>
                                    <span>-</span>
                                    <span style={{width: '40%', height: 40}} >
                                        {s.timeEnd}
                                    </span>
                                </div>
                                <div style={{width: 150}} className="d-flex justify-content-center">
                                    <button onClick={() => handleDelete(s.id)} className="deleteBtn" style={{background:'red'}}>
                                        {global.delete[+localStorage.getItem('atsLang')  || 1 ]}
                                    </button>
                                </div>

                            </div>
                        ))}
                        <div className="d-flex flex-row text-center" style={{width: 'fit-content',margin:'10px 0'}}>

                            <div style={{width: 170}}>
                                <Select
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    onChange={(ev) => handleTimeChange('num', ev.value.split('_')[0], ev.value.split('_')[1])}
                                    menuPortalTarget={document.body}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    defaultValue={graphic.num+ '_' + graphic?.notes}
                                    name="color"
                                    options={sipData.map(k => (
                                        {value:k.buddy + '_' + k.notes,label:`${k.buddy} ( ${k.notes} )`}
                                    ))}
                                />
                            </div>
                            <div style={{width: 150}}>
                                <select value={graphic.weekStart} onChange={(ev) => handleTimeChange('weekStart',ev.target.value)} style={{width: '40%', height: 40}}>
                                    <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    {weekDays.map((k,_) => (
                                        <option key={_} value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                    ))}
                                </select>
                                <span>-</span>
                                <select value={graphic.weekEnd} onChange={(ev) => handleTimeChange('weekEnd',ev.target.value)} style={{width: '40%', height: 40}}>
                                    <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    {weekDays.map((k,_) => (
                                        <option key={_} value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{width: 150}}>
                                <Flatpickr
                                    data-enable-time
                                    noCalendar
                                    style={{width: '40%', border: '1px solid',height: 35,textAlign:"center", fontSize:14}}
                                    value={graphic.timeStart}
                                    onChange={([ev]) => handleTimeChange('timeStart',ev)}
                                    options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        time_24hr: true,
                                        allowInput: true,
                                    }}
                                />
                                {/*<input onChange={(ev) => handleTimeChange('timeStart',ev.target.value)} value={graphic.timeStart} style={{width: '40%', border: '1px solid',height: 35, fontSize:14}} type="time" step="1"/>*/}
                                <span>-</span>
                                <Flatpickr
                                    data-enable-time
                                    noCalendar
                                    style={{width: '40%', border: '1px solid',height: 35,textAlign:"center", fontSize:14}}
                                    value={graphic.timeEnd}
                                    onChange={([ev]) => handleTimeChange('timeEnd',ev)}
                                    options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        time_24hr: true,
                                        allowInput: true,
                                    }}
                                />
                                {/*<input onChange={(ev) => handleTimeChange('timeEnd',ev.target.value)} value={graphic.timeEnd} style={{width: '40%', height: 40}} type="time"/>*/}
                            </div>
                            <div style={{width: 150}} className="d-flex justify-content-center">
                                <button onClick={handleAddGraphic} className="addBtn">
                                    {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                        <button onClick={handleAdd} className="addBtnBig">
                            {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddShort;
