import React, {useCallback, useEffect, useState} from 'react';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Api from "../Api";
import Account from "../helpers/Account";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {profileRequest, profileUrsRequest} from "../store/actions/users";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";

function UrsOnline(props) {
    const [online, setUrsOnline] = useState({})
    const navigate = useNavigate()
    const profile = useSelector(state => state.users.ursData)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchUrsOnline = async () => {
            try {
                const { data } = await Api.ursOnline();
                let l = data.rows.reduce((acc, obj) => {
                    const key = obj.serverip;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(obj);
                    return acc;
                }, {});
                setUrsOnline(l);
            } catch (e) {
                console.log(e);
            }
        };
        const intervalId = setInterval(fetchUrsOnline, 4000);
        fetchUrsOnline();
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const handleId = useCallback(async (id) => {
        try {
            Cookies.set('viewer', id)
            Cookies.set('original', profile.id)
            Cookies.set('key', profile.uid)
            const {data} = await Api.loginAsUser(id, profile.id, profile.uid)
            localStorage.setItem('access_token', data.access_token)
            localStorage.setItem('refresh_token', data.refresh_token)
            await dispatch(profileRequest())
            navigate('/')
        }catch (e) {

        }
    }, [profile])
    useEffect(() => {
        if (!Account.getUrsToken()) {
            navigate('/')
        }
    }, [])
    useEffect(() => {
        (async () => {
            await dispatch(profileUrsRequest())
        })()
    }, [])
    let index = 1
    return (
        <>
            <Helmet>
                <title>Online</title>
            </Helmet>
            <div className="container">
                <div style={{marginTop: 45}} className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={10}>
                                            Online
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        >
                                            N
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Date
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                        >
                                            Duration
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Status
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Description
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Fio
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            From
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            To
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                        >
                                            In Num
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Ext Num
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Line
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                {Object.keys(online).map(m => {
                                    return (
                                        <TableBody>
                                            <TableRow key={m}>
                                                <TableCell align="center" colSpan={10}><span className="online_span"><hr/>
                                                    {m}
                                                    <hr/></span></TableCell>
                                            </TableRow>
                                            {online[m].map((s,i) => {
                                                index += 1
                                                return (
                                                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                                                        <TableCell align="center">
                                                            {index}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {new Date(s.start).toLocaleTimeString()}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {s?.ctime}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {s?.row2[0]?.disposition}
                                                        </TableCell>
                                                        <TableCell align="center" style={{width:100}}>
                                                            {s?.row3[0]?.anun}
                                                        </TableCell>
                                                        <TableCell onClick={() => handleId(s?.member)} align="center" style={{width:100,textDecoration:'underline',cursor:'pointer'}}>
                                                            {s?.fio}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {s?.ext_con ? s.ext_con + '-' : ''}
                                                            {s?.extension}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {s?.dst_con ? s.dst_con + '-' : s?.row2[0]?.destination !== 's'?s?.row2[0]?.destination:s?.row1[0]?.destination}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {s?.row1[0]?.in_num}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {s?.row1[0]?.ext_num?s?.row1[0]?.ext_num:s?.row2[0]?.ext_num}

                                                        </TableCell>
                                                        <TableCell align="center" style={{width:100}}>
                                                            {s?.dlines? s?.dlines : s.row1[0]?.dlines}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    )

                                })
                                }

                            </Table>
                        </TableContainer>
                    </Paper>

                </div>

            </div>
        </>
    );
}

export default UrsOnline;
