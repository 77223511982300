import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import tel from '../assets/img/telegram-logo.png'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {toast} from "react-toastify";
import Api from "../Api";
import Account from "../helpers/Account";
import {lang, login} from "../translate";
import Cookies from "js-cookie";
import app from "../assets/img/playstore.png";
import apple from "../assets/img/apple-icon.png";
import Ucom from "../assets/img/Ucom.jpg";
import ats from "../assets/img/footer_logo.png";
import InputMask from 'react-input-mask';
import axios from "axios";
import {REACT_APP_API_URL} from '../config';
import LogRegSide from "../components/LogRegSide";
import qs from "query-string";

const {REACT_APP_SERVER} = process.env;

function Login(props) {
    const [phone, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [ip, setIp] = useState('')
    const [show, setShow] = useState(false)
    const [lang1, setLang] = useState(1)
    const navigate = useNavigate()
    const location = useLocation()
    const handleSubmit = useCallback(async (ev) => {
        try {
            ev.preventDefault()
            if (!phone || !password.trim()) {
                toast.error('Enter phone and password');
                return;
            }
            let newPhone = phone.replaceAll(' ','').replace('(','').replace(')','')
            if(REACT_APP_SERVER === 'Beeline'){
                const {data} = await Api.login({phone:newPhone, password, lang: lang[+localStorage.getItem('atsLang')]})
                Account.setAdminToken(data)
                navigate(`/`)
            }else{
                if(newPhone === '37455555555'){
                    const {data} = await Api.login({phone:newPhone, password:'admin12345', lang: lang[+localStorage.getItem('atsLang')]})
                    Account.setAdminToken(data)
                    navigate(`/`)
                }else{
                    await Api.login({phone:newPhone, password, lang: lang[+localStorage.getItem('atsLang')]})
                    Cookies.set('login', 'progress')
                    navigate(`/confirm/user/${newPhone}`)
                }

            }
        } catch (e) {
            toast.error(e.response.data.message);

        }
    }, [phone, password,REACT_APP_SERVER,lang])
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    useEffect(() => {
        Account.logoutAdmin()
    }, [])
    useEffect(() => {
        (async () => {
            const {data} = await axios.get('https://api.ipify.org?format=json')
            setIp(data.ip)
            if(query.phone === '37455555555'){
                const {data} = await Api.login({phone:query.phone, password:'admin12345', lang: lang[+localStorage.getItem('atsLang')]})
                Account.setAdminToken(data)
                navigate(`/`)
            }
        })()
    }, [location.search])
    const handleLangChange = useCallback((lang) => {
        setLang(lang)
        localStorage.setItem('atsLang', lang)
    }, [])

    return (
        <>
            <Helmet>
                <title>{login.title[lang1]}</title>
            </Helmet>
            <div className="adminLogin">
                <div className="loginContainer" style={REACT_APP_SERVER === 'Beeline'?{borderRadius:20}:{}}>
                    <div className="adminLoginHeader">
                        <div>
                            <a className="loginLink"
                                  href={'/operator/login'}>Operator</a> | <a
                            className="loginLink" href={'/login'}>Admin</a>
                            {REACT_APP_SERVER !== 'Beeline'?<p>IP: {ip}</p>:null}
                        </div>
                        <div>
                            <span style={{cursor: 'pointer', fontSize: 13,}} onClick={() => handleLangChange(3)}>
                               hy
                            </span>
                            <span style={{margin: '0 5px'}}>
                               |
                            </span>
                            <span style={{cursor: 'pointer', fontSize: 13,}} onClick={() => handleLangChange(2)}>
                               ru
                            </span>
                            <span style={{margin: '0 5px'}}>
                               |
                            </span>
                            <span style={{cursor: 'pointer', fontSize: 13,}} onClick={() => handleLangChange(1)}>
                               eng
                            </span>
                        </div>
                    </div>
                    <div className="adminLoginPage">

                        <form onSubmit={handleSubmit} className="adminLoginForm">

                            <div className="adminLoginMain">
                                <label
                                    className="adminInput"
                                >
                                {/*    <input*/}
                                {/*    // style={{border:'none', outline:'none',}}*/}
                                {/*    type="text"*/}
                                {/*    placeholder={login.username[lang1]}*/}
                                {/*    value={phone}*/}
                                {/*    onChange={(ev) => handleInputChange(ev.target.value)}*/}
                                {/*/>*/}

                                    <InputMask
                                        mask="374 (99) 999999"
                                        maskChar="_"
                                        placeholder="374 (__) ______"
                                        onChange={(ev) => setUserName(ev.target.value)}
                                        value={phone}
                                    >
                                        {(inputProps) => <input {...inputProps} />}
                                    </InputMask>
                                </label>
                                <label htmlFor="adminPass" style={{display: 'flex'}} className="adminFormLabel">
                                    <input
                                        id="adminPass"
                                        value={password}
                                        placeholder={login.pass[lang1]}
                                        type={show ? 'text' : 'password'}
                                        onChange={(ev) => setPassword(ev.target.value)}
                                    />
                                    {show
                                        ? <VisibilityIcon fontSize="small" onClick={() => setShow(false)}/>
                                        : <VisibilityOffIcon fontSize="small" onClick={() => setShow(true)}/>}
                                </label>
                                <button type="submit" onClick={handleSubmit}
                                        className="adminLoginBtn">{login.title[lang1]}</button>
                                {REACT_APP_SERVER === 'V-office'?<span>Not registered ? <Link to="/registration">Create an account</Link></span>:null}

                            </div>
                        </form>
                    </div>
                </div>
                {REACT_APP_SERVER === 'Beeline'?null:  <LogRegSide/>
                }
            </div>
        </>

    );
}

export default Login;
