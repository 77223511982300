import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, lang} from "../translate";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-hy';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/i18n/datepicker-en-AU';
import {toast} from "react-toastify";
import Api from "../Api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 400 ? 400 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '300px',
    overflow: 'auto'
};

function Billing({open, setOpen, setData}) {
    const [billing, setBilling] = useState(1)
    const [billings,setBillings] = useState([])
    useEffect(() => {
        (async () => {
           try{
               const {data} = await Api.getBilling()
               setBillings(data.billing)
           } catch (e) {
               console.log(e)
           }
        })()
    }, [])
    useEffect(() => {
        setBilling(billings.find(b => b.name === open.name)?.item)
    }, [open,billings])
    const handleBilling = useCallback(async () => {
        try{
            await Api.billing({billing,id:open.member},location.pathname)
            setData((prevData) => {
                const updatedMembers = prevData.members.map((member) => {
                    if (member.id === open.member) {
                        return {...member, billing: {name:billings.find(b => b.item === billing)?.name}};
                    }
                    return member;
                });
                return {...prevData, members: updatedMembers};
            });
            setOpen({member:0,name:''})
        }catch (e) {toast.error(e.response.data.message)}
    }, [billing,billings,open])

    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open.member}
                onClose={() => setOpen({member:0,name:''})}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Billing
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => setOpen(0)}>
                            x
                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <div className="row w-100">
                                <div>
                                    <select style={{height:40,border:'1px solid'}} value={billing} onChange={(e) => setBilling(+e.target.value)} className="w-100">
                                        {billings.map(b => (
                                            <option value={b.item}>{b.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex align-items-center flex-column">

                            </div>
                            <div className="d-flex justify-content-center">
                                <button onClick={handleBilling} className="addBtn">
                                    {global.edit[+localStorage.getItem('atsLang')  || 1]}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default Billing;
