import React, {useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import {
    faArrowUp19,
    faArrowUpRightFromSquare,
    faFolder,
    faGlobe,
    faLock,
    faLockOpen,
    faShieldHalved,
    faSubscript
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {menu} from "../translate";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Cookies from 'js-cookie'
import {useSelector} from "react-redux";
import integration from '../assets/img/integration.svg'

const {REACT_APP_SERVER} = process.env;

function Menu({isAside, lang}) {
    const [items, setItems] = useState([1, 2, 3, 4]);
    const countData = useSelector((state) => state.users.countData)

    return (
        <>

            {window.innerWidth < 1030 ?
                <div style={isAside ? {left: 0} : {left: '-100%'}} className="aside">
                    <div className="py-2" style={{padding: 0}}>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" style={{background: '#7EBC09'}} id="headingOne">
                                    <NavLink to="/" className="accordion-button active">
                                        <HomeIcon/>
                                        {menu.main[lang]}
                                    </NavLink>
                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingDev`}>
                                    <button
                                        style={{justifyContent: 'space-between'}}
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseDev`}
                                        aria-expanded={window.location.pathname === '/sip_number' || window.location.pathname === '/included' ? "false" : 'false'}
                                        aria-controls={`collapseDev`}
                                    >
                                        <span>
                                             <CallIcon/>
                                            {menu.device[lang]}
                                        </span>
                                        <span>
                                            {countData?.sip + countData?.include}
                                        </span>

                                    </button>

                                </h2>
                                <div
                                    id={`collapseDev`}
                                    className={"accordion-collapse collapse" + (window.location.pathname === '/sip_number' || window.location.pathname === '/included' ? " show" : ' hide')}
                                    aria-labelledby={`headingDev`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <NavLink to='/sip_number' style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p><span/>
                                            {menu.sip[lang]} </p>
                                        <p>{countData?.sip}</p>
                                    </NavLink>

                                    <NavLink to='/included' style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p>
                                            <span/>
                                            {menu.included[lang]}
                                        </p>


                                        <p>{countData?.include}</p>


                                    </NavLink>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingShort`}>
                                    <button
                                        style={{justifyContent: 'space-between'}}
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseShort`}
                                        aria-expanded={window.location.pathname === '/short_numbers' || window.location.pathname === '/ivr' ? "false" : 'false'}
                                        aria-controls={`collapseShort`}
                                    >
                                        <span>
                                            <FontAwesomeIcon icon={faArrowUp19}/>
                                            {menu.short[lang]}
                                       </span>
                                        <span>{countData?.short + countData?.ivr}</span>
                                    </button>

                                </h2>
                                <div
                                    id={`collapseShort`}
                                    className={`accordion-collapse collapse` + (window.location.pathname === '/short_numbers' || window.location.pathname === '/ivr' ? " show" : ' hide')}
                                    aria-labelledby={`headingShort`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <NavLink to='/short_numbers' style={{paddingLeft: 40}}
                                             className="accordion-button accordion-link"> <span/>
                                        {menu.shortC[lang]}</NavLink>

                                    <NavLink to='/ivr' style={{paddingLeft: 40}}
                                             className="accordion-button accordion-link"> <span/>
                                        {menu.ivr[lang]}</NavLink>

                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingVirtual`}>
                                    <button
                                        style={{justifyContent: 'space-between'}}
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseVirtual`}
                                        aria-expanded={window.location.pathname === '/did_numbers' || window.location.pathname === '/sip_provider' ? "false" : 'false'}
                                        aria-controls={`collapseVirtual`}
                                    >

                                        <span>
                                            <FontAwesomeIcon icon={faGlobe}/>

                                            {menu.virtual[lang]}
                                        </span>
                                        <span>{countData?.did + countData?.provider}</span>
                                    </button>

                                </h2>
                                <div
                                    id={`collapseVirtual`}
                                    className={`accordion-collapse collapse` + (window.location.pathname === '/did_numbers' || window.location.pathname === '/sip_provider' ? " show" : ' hide')}
                                    aria-labelledby={`headingVirtual`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <NavLink to='/did_numbers'
                                             style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p><span/>
                                            {menu.did[lang]}</p>
                                        <p>{countData?.did}</p>
                                    </NavLink>

                                    <NavLink to='/sip_provider'
                                             style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p>
                                            <span/>
                                            {menu.provide[lang]}
                                        </p>
                                        <p>
                                            {countData?.provider}
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingRedirect`}>
                                    <button
                                        style={{justifyContent: 'space-between'}}
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseRedirect`}
                                        aria-expanded={window.location.pathname === '/forward' || window.location.pathname === '/rout_phone_number' ? "false" : 'false'}
                                        aria-controls={`collapseRedirect`}
                                    >

                                        <span>
                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                            {menu.callForward[lang]}
                                        </span>
                                        {Cookies.get('viewer') && Cookies.get('original') ?
                                            <span>{countData?.forward + countData?.redirect}</span> :
                                            <span>{countData?.forward}</span>}
                                    </button>

                                </h2>
                                <div
                                    id={`collapseRedirect`}
                                    className={`accordion-collapse collapse` + (window.location.pathname === '/forward' || window.location.pathname === '/rout_phone_number' ? " show" : ' hide')}
                                    aria-labelledby={`headingRedirect`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <NavLink to='/forward' style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">

                                        <p><span/>
                                            {menu.forward[lang]}</p>
                                        <p>{countData?.forward}</p>
                                    </NavLink>

                                    {Cookies.get('viewer') && Cookies.get('original') ?
                                        <NavLink to='/rout_phone_number'
                                                 style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                                 className="accordion-button accordion-link">
                                            <p>
                                                <span/>
                                                {menu.call[lang]}
                                            </p>
                                            <p>{countData?.redirect}</p>
                                        </NavLink> : null}

                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <NavLink
                                        to='/auto_call'
                                        className="accordion-button accordion-link justify-content-between"
                                    >
                                        <p>
                                            <FontAwesomeIcon icon={faShieldHalved}/>
                                            {menu.autoCall[lang]}
                                        </p>
                                        <p>{countData?.auto}</p>
                                    </NavLink>

                                </h2>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <NavLink
                                        to='/files'
                                        className="accordion-button accordion-link"
                                    >
                                        <FontAwesomeIcon icon={faFolder}/>

                                        {menu.file[lang]}

                                    </NavLink>

                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <NavLink
                                        to='/blocked'
                                        className="accordion-button accordion-link"
                                    >
                                        <FontAwesomeIcon icon={faShieldHalved}/>

                                        {menu.block[lang]}

                                    </NavLink>

                                </h2>
                            </div>
                            {Cookies.get('viewer') ? <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <NavLink
                                        to='/ip'
                                        className="accordion-button accordion-link"
                                    >
                                        <FontAwesomeIcon icon={faLockOpen}/>

                                        {menu.ip[lang]}

                                    </NavLink>

                                </h2>
                            </div> : <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingIp`}>
                                    <NavLink
                                        to='/blocked_ip'
                                        className="accordion-button accordion-link"
                                    >
                                        <FontAwesomeIcon icon={faLock}/>

                                        {menu.blockIp[lang]}
                                    </NavLink>

                                </h2>
                            </div>}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <NavLink to="/history" className="accordion-button accordion-link"><NewspaperIcon
                                        sx={{fontSize: 20}}/> {menu.history[lang]}</NavLink>
                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <Link target="_blank" to="/online" rel="noopener noreferrer"
                                          className="accordion-button accordion-link"><AccessTimeFilledIcon
                                        sx={{fontSize: 20}}/> {menu.online[lang]}</Link>
                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <Link to='/profile' className="accordion-button accordion-link"><AccountCircleIcon
                                        sx={{fontSize: 20}}/> {menu.profile[lang]}</Link>
                                </h2>
                            </div>
                            <div className="accordion-item">
                                {REACT_APP_SERVER !== 'Beeline' ? <div className="accordion-item">
                                    <h2 className="accordion-header" id={`headingIp`}>
                                        <NavLink
                                            to='/crm_integration'
                                            className="accordion-button crm_img accordion-link"
                                        >
                                            <img style={{marginRight: 7}} src={integration} alt=""/>

                                            {menu.crm[lang]}
                                        </NavLink>

                                    </h2>
                                </div> : null}
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <Link
                                        to='/login'
                                        style={{cursor: 'pointer'}}
                                        className="accordion-button accordion-link"
                                    >
                                        <LockIcon sx={{fontSize: 20}}/> {menu.logout[lang]}

                                    </Link>

                                </h2>
                            </div>

                        </div>
                    </div>
                </div>
                : window.location.pathname !== '/history' && window.location.pathname !== '/graphic' ?
                    <div className="py-2" style={{width: '24%'}}>
                        <div className="newSide accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" style={{background: '#7EBC09'}} id="headingOne">
                                    <NavLink to="/" className="accordion-button active">
                                        <HomeIcon/>
                                        {menu.main[lang]}
                                    </NavLink>
                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingDev`}>
                                    <button
                                        style={{justifyContent: 'space-between'}}
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseDev`}
                                        aria-expanded={window.location.pathname === '/sip_number' || window.location.pathname === '/included' ? "false" : 'false'}
                                        aria-controls={`collapseDev`}
                                    >

                                       <span>
                                            <CallIcon/>
                                           {menu.device[lang]}
                                       </span>
                                        <span>
                                            {countData?.sip + countData?.include}
                                        </span>
                                    </button>

                                </h2>
                                <div
                                    id={`collapseDev`}
                                    className={"accordion-collapse collapse" + (window.location.pathname === '/sip_number' || window.location.pathname === '/included' ? " show" : ' hide')}
                                    aria-labelledby={`headingDev`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <NavLink to='/sip_number' style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p><span/>
                                            {menu.sip[lang]} </p>
                                        <p>{countData?.sip}</p>
                                    </NavLink>

                                    <NavLink to='/included' style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p>
                                            <span/>
                                            {menu.included[lang]}
                                        </p>


                                        <p>{countData?.include}</p>


                                    </NavLink>

                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingShort`}>
                                    <button
                                        style={{justifyContent: 'space-between'}}
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseShort`}
                                        aria-expanded={window.location.pathname === '/short_numbers' || window.location.pathname === '/ivr' ? "false" : 'false'}
                                        aria-controls={`collapseShort`}
                                    >
                                       <span>
                                            <FontAwesomeIcon icon={faArrowUp19}/>
                                           {menu.short[lang]}
                                       </span>
                                        <span>{countData?.short + countData?.ivr}</span>
                                    </button>

                                </h2>
                                <div
                                    id={`collapseShort`}
                                    className={`accordion-collapse collapse` + (window.location.pathname === '/short_numbers' || window.location.pathname === '/ivr' ? " show" : ' hide')}
                                    aria-labelledby={`headingShort`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <NavLink to='/short_numbers'
                                             style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p>
                                            <span/>
                                            {menu.shortC[lang]}
                                        </p>
                                        <p>
                                            {countData?.short}
                                        </p>
                                    </NavLink>

                                    <NavLink to='/ivr' style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p>
                                            <span/>
                                            {menu.ivr[lang]}
                                        </p>
                                        <p>
                                            {countData?.ivr}
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingVirtual`}>
                                    <button
                                        style={{justifyContent: 'space-between'}}
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseVirtual`}
                                        aria-expanded={window.location.pathname === '/did_numbers' || window.location.pathname === '/sip_provider' ? "false" : 'false'}
                                        aria-controls={`collapseVirtual`}
                                    >

                                        <span>
                                            <FontAwesomeIcon icon={faGlobe}/>

                                            {menu.virtual[lang]}
                                        </span>
                                        <span>{countData?.did + countData?.provider}</span>
                                    </button>

                                </h2>
                                <div
                                    id={`collapseVirtual`}
                                    className={`accordion-collapse collapse` + (window.location.pathname === '/did_numbers' || window.location.pathname === '/sip_provider' ? " show" : ' hide')}
                                    aria-labelledby={`headingVirtual`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <NavLink to='/did_numbers'
                                             style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p><span/>
                                            {menu.did[lang]}</p>
                                        <p>{countData?.did}</p>
                                    </NavLink>

                                    <NavLink to='/sip_provider'
                                             style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">
                                        <p>
                                            <span/>
                                            {menu.provide[lang]}
                                        </p>
                                        <p>
                                            {countData?.provider}
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingRedirect`}>
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseRedirect`}
                                        aria-expanded={window.location.pathname === '/forward' || window.location.pathname === '/rout_phone_number' ? "false" : 'false'}
                                        style={{justifyContent: 'space-between'}}
                                        aria-controls={`collapseRedirect`}
                                    >
                                        <span>
                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                            {menu.callForward[lang]}
                                        </span>
                                        {Cookies.get('viewer') && Cookies.get('original') ?
                                            <span>{countData?.forward + countData?.redirect}</span> :
                                            <span>{countData?.forward}</span>}
                                    </button>

                                </h2>
                                <div
                                    id={`collapseRedirect`}
                                    className={`accordion-collapse collapse` + (window.location.pathname === '/forward' || window.location.pathname === '/rout_phone_number' ? " show" : ' hide')}
                                    aria-labelledby={`headingRedirect`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <NavLink to='/forward' style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                             className="accordion-button accordion-link">

                                        <p><span/>
                                            {menu.forward[lang]}</p>
                                        <p>{countData?.forward}</p>
                                    </NavLink>

                                    {Cookies.get('viewer') && Cookies.get('original') ?
                                        <NavLink to='/rout_phone_number'
                                                 style={{paddingLeft: 40, justifyContent: 'space-between'}}
                                                 className="accordion-button accordion-link">
                                            <p>
                                                <span/>
                                                {menu.call[lang]}
                                            </p>
                                            <p>{countData?.redirect}</p>
                                        </NavLink> : null}

                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <NavLink
                                        to='/auto_call'
                                        className="accordion-button accordion-link justify-content-between"
                                    >
                                        <p>
                                            <FontAwesomeIcon icon={faShieldHalved}/>
                                            {menu.autoCall[lang]}
                                        </p>
                                        <p>{countData?.auto}</p>
                                    </NavLink>

                                </h2>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingFile`}>
                                    <NavLink
                                        to='/files'
                                        className="accordion-button accordion-link justify-content-between"
                                    >
                                        <p>
                                            <FontAwesomeIcon icon={faFolder}/>
                                            {menu.file[lang]}
                                        </p>
                                        <p>{countData?.file}</p>
                                    </NavLink>

                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingBlock`}>
                                    <NavLink
                                        to='/blocked'
                                        className="accordion-button accordion-link"
                                    >
                                        <FontAwesomeIcon icon={faShieldHalved}/>

                                        {menu.block[lang]}
                                    </NavLink>

                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingIp`}>
                                    <NavLink
                                        to='/short_function'
                                        className="accordion-button accordion-link"
                                    >
                                        <FontAwesomeIcon icon={faSubscript}/>

                                        {menu.shortF[lang]}
                                    </NavLink>

                                </h2>
                            </div>
                            {Cookies.get('viewer') ? <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingAuto`}>
                                    <NavLink
                                        to='/ip'
                                        className="accordion-button accordion-link"
                                    >
                                        <FontAwesomeIcon icon={faLockOpen}/>

                                        {menu.ip[lang]}

                                    </NavLink>

                                </h2>
                            </div> : null}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingIp`}>
                                    <NavLink
                                        to='/blocked_ip'
                                        className="accordion-button accordion-link"
                                    >
                                        <FontAwesomeIcon icon={faLock}/>

                                        {menu.blockIp[lang]}
                                    </NavLink>

                                </h2>
                            </div>
                            {REACT_APP_SERVER !== 'Beeline' ? <div className="accordion-item">
                                <h2 className="accordion-header" id={`headingIp`}>
                                    <NavLink
                                        to='/crm_integration'
                                        className="accordion-button crm_img accordion-link"
                                    >
                                        <img style={{marginRight: 7}} src={integration} alt=""/>

                                        {menu.crm[lang]}
                                    </NavLink>

                                </h2>
                            </div> : null}
                            {/*<div className="accordion-item">*/}
                            {/*    <h2 className="accordion-header" id={`headingIp`}>*/}
                            {/*        <NavLink*/}
                            {/*            to='/ip'*/}
                            {/*            className="accordion-button accordion-link"*/}
                            {/*        >*/}
                            {/*            <FontAwesomeIcon icon={faLockOpen}/>*/}

                            {/*            {menu.ip[lang]}*/}
                            {/*        </NavLink>*/}

                            {/*    </h2>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    : null}
        </>
    );
}

export default Menu;
