import {
    DID_ADD_LOCAL,
    DID_CREATE_GET_SUCCESS, DID_CREATE_SUCCESS,
    DID_DELETE_LOCAL, DID_EDIT_SUCCESS,
    DID_FAIL,
    DID_REQUEST,
    DID_SUCCESS
} from '../actions/did';

const initialState = {
    didData: [],
    didCreateData: {},
    didSingleData: {},
    didStatus: '',
    didSingleStatus: '',
    page:1,
    count:0
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case  DID_REQUEST: {
            return {
                ...state,
                didStatus: 'request',
                // didData: [],
            };
        }
        case  DID_SUCCESS: {
            return {
                ...state,
                didStatus: 'success',
                didData: action.payload.data.did,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        }
        case  DID_CREATE_SUCCESS: {
            return {
                ...state,
                didStatus: 'success',
                didData: action.payload.data.did,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        }
        case  DID_EDIT_SUCCESS: {
            return {
                ...state,
                didStatus: 'success',
                didData: action.payload.data.did,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        }
        case  DID_CREATE_GET_SUCCESS: {
            return {
                ...state,
                didStatus: 'success',
                didCreateData: action.payload.data.inputNumber,
            };
        }
        case  DID_FAIL: {
            return {
                ...state,
                didStatus: 'fail',
            };
        }
        case  DID_ADD_LOCAL: {
            return {
                ...state,
                didData: [...state.didData, {...action.payload.data, id: new Date()}],

            };
        }
        case  DID_DELETE_LOCAL: {
            return {
                ...state,
                didData: state.didData.filter(d => d.pref + d.did !== action.payload.id && d.provider === action.payload.provider),

            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
