import React, {useEffect, useState} from 'react';
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal, Typography} from "@mui/material";
import {file, menu, moneyT,} from "../translate";
import Api from "../Api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 750 ? 750 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: window.innerHeight > 600 ? 600 : '97vh',
    overflow: 'auto'
};

function MoneyChange({open, setOpen}) {
    const [money, setMoneyChange] = useState([])

    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.moneyHistory()
                setMoneyChange(data.price_history)
            } catch (e) {

            }
        })()
    }, [])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {menu.amount[+localStorage.getItem('atsLang')  || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            x
                        </span>
                    </div>
                    <div>
                        {money.length ? money.map((m, _) => (
                            <div  key={_}>
                                <div align="center" style={{padding: '10px 15px'}}>
                                    <b>{moneyT[m.service][+localStorage.getItem('atsLang')  || 1]}</b> - {+m.total_price}
                                </div>
                                <div align="center" style={{padding: '10px 15px'}}>
                                    <b>Changed at</b> - {new Date(m.sdate).toLocaleString()}
                                </div>
                                <hr/>
                            </div>
                        )) : <div align="center" style={{padding: '10px 15px'}}>
                            no data
                        </div>}

                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default MoneyChange;
