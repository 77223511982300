import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import BitrixEnter from "../components/BitrixEnter";
import {useSelector} from "react-redux";
import Api from "../Api";
import {useNavigate,useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import axios from "axios";

function CrmIntegration(props) {
    const [bitrix,setBitrix] = useState(false)
    const [isInstalled,setIsInstalled] = useState(false)
    const userData = useSelector((state) => state.users.userData)
    const navigate = useNavigate()
    const [crm, setCrm] = useState([])
    useEffect(() => {
        setIsInstalled(userData.bitrix?.member)
    },[userData])
    const handleDelete = useCallback(async () => {
        try {
            await Api.bitrix_delete()
            setIsInstalled(false)
        }catch (e) {

        }
    }, [])
    const location = useLocation()
    useEffect(() => {
        if(location.hash === '#application_not_found'){
            setBitrix(true)
        }
    }, [location.hash])
    useEffect(() => {
        (async () => {
            const {data} = await axios.get(`https://ats.am/api/crm_integration?lang=${localStorage.getItem('atsLang')}`)
            setCrm(data.crm)
        })()
    },[])
    return (
        <Wrapper>
            <Helmet>
                <title>CRM integration</title>
            </Helmet>

            <div className="d-flex flex-wrap">
                {crm?.map(c => {
                    if(c.name){
                        return (<div key={c.id} className="crmBlock" style={{border: "1px solid rgb(204, 204, 204)", padding: "10px"}}>
                                <div>
                                    <div className=" blog-item-img d-flex flex-row align-items-center justify-content-center" style={{height: 140}}>
                                        <img
                                            src={"https://ats.am"+c.image}
                                            alt="" className="img-responsive" style={{width: "75%", height: "75%", objectFit: "contain"}}/>
                                    </div>
                                </div>
                                <div>

                                    <div className="blog-item-content"><h4 style={{marginBottom:25}}>{c.crm_integration_translate[0].title}</h4>
                                        <p style={{marginBottom:25}}>{c.crm_integration_translate[0].desc}</p>
                                        {!isInstalled?<div style={{borderRadius: 5}} className="addBtnBig" onClick={() => setBitrix(!bitrix)}>
                                            Add
                                        </div>:<div className="d-flex justify-content-between">
                                            <div style={{borderRadius: 5}} className="deleteBtn addBtnBig" onClick={handleDelete}>
                                                Delete
                                            </div>
                                            <div style={{borderRadius: 5}} className="addBtnBig" onClick={()=> navigate(`/crm_integration/${c.name}`)}>
                                                View
                                            </div>
                                        </div>}
                                    </div>


                                </div>
                            </div>
                        )
                    }
                })}
                <BitrixEnter open={bitrix} setOpen={setBitrix} />
            </div>
        </Wrapper>
    );
}

export default CrmIntegration;
