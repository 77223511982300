export const menu = {
    main:['','Home','Главная','Գլխավոր'],
    ogg:['','Home','КПД','ՕԳԳ'],
    shortF:['','Home','Главная','Կարճ գործառույթ'],
    device:['','Device','Устройства ','Սարքավորումներ '],
    sip:['','Sip Numbers','SIP Номера','SIP համարներ'],
    included:['','Included mob. numbers','Внутренние моб. номера','Ներառված հեռ.'],
    short:['','Short numbers / IVR','Короткие номера / IVR','Կարճ համարներ/խմբեր'],
    shortC:['','Short numbers','Короткие номера','Կարճ ենթահամարներ'],
    ivr:['','Voice menu (IVR)','Голосовое меню (IVR)','Ձայնային մենյու (IVR)'],
    virtual:['','Virtual numbers','Виртуальные ном.','Վիրտուալ հեռ.'],
    did:['','DID numbers','DID номера','DID հեռախոսահամար'],
    provide:['','SIP providers','SIP Операторы','SIP պրովայդերներ'],
    callForward:['','Call FW & Redirects','Управление звонками','Զանգերի ուղորդում'],
    forward:['','Forward','Переадресация звонков','Վերահասցեավորումներ'],
    call:['','Call rout','Маршрутизация звонка','Զանգերի ուղորդում'],
    autoCall:['','Autocall / Group call','Автозв. / Груп. вызов','Ավտո. / Խմբային զանգ'],
    autoCall1:['','Autocall','Автозвонок','Ավտոզանգ'],
    file:['','Files','Работа с файлами','Աշխատանք ֆայլերի հետ'],
    block:['','Blocked Phones','Заблокированные номера','Արգելափակված համարներ'],
    blockIp:['','Blocked IP list','Заблокированные ip','Արգելափակված Ip - ներ'],
    ip:['','My IP list','Mой список ip','Արտոնված Ip - ներ'],
    pass:['','Send new password','Отправить новый пароль','Ուղարկեք նոր գաղտնաբառ'],
    history:['','History','История','Պատմություն'],
    chart:['','Anallitics','Аналитика','Պատմություն'],
    online:['','Online','Онлайн','Առցանց'],
    logout:['','Logout','Выход','Դուրս գալ'],
    amount:['','Amount','Сумма','Գումարը'],
    profile:['','Profile','Профиль','Պրոֆիլը'],
    crm:['','Integrations','Интеграции','Ինտեգրումներ']
}
export const input={
    search:['','Search','Поиск','Որոնում'],
    select:['','Select','Выберите из списка','Ընտրել ցանկից']
}

export const bitrix={
    header:['','Connecting integration with Bitrix24','Подключение интеграции с Битрикс24','Ինտեգրման միացում Bitrix24-ի հետ'],
    title:['','Install the V-office application','Установите приложение V-office от It-Spark','Տեղադրեք V-office հավելվածը It-Spark-ից'],
    titleA:['','Install the ATS.AM application','Установите приложение ATS.AM от It-Spark','Տեղադրեք ATS.AM հավելվածը It-Spark-ից'],
    title2:['','Set up the connection','Настройте подключение','Կարգավորեք ձեր կապը'],
    paragraph_p1:['','Open ', 'Откройте ',
        'Բացեք '],
    paragraph2:['','Enter bitrix domain', 'Введите домен bitrix', 'Մուտքագրեք bitrix Դոմենը'],
    paragraph_p2:['',' and click Install. ', ' и нажмите Установить.', ' և սեղմեք Տեղադրել:'],
    link:{text:['',
        'Bitrix24.Market / Integration with telephony /V-office from It-Spark',
        'Битрикс24.Маркет / Интеграция с телефонией / Приложение V-office от It-Spark',
        'Bitrix24.Market / Ինտեգրում հեռախոսակապին /V-office հավելվածը It-Spark-ից'
        ],
        textA:['',
        'Bitrix24.Market / Integration with telephony /ATS.AM from It-Spark',
        'Битрикс24.Маркет / Интеграция с телефонией / Приложение ATS.AM от It-Spark',
        'Bitrix24.Market / Ինտեգրում հեռախոսակապին /ATS.AM հավելվածը It-Spark-ից'
        ],link:''}

}



export const sip = {
    phone:['','Phone num.','Номер','Համար'],
    note:['','Note','Описание','Ն. Գրութ'],
    out:['','Outline','Линия исход.',"Ելք․ գիծ"],
    forward:['','Forward','Переадресация','Վերահասցեավորում'],
    outgoing:['','Management outgoing','Управл. исходящими','Թ. ուղղ.'],
    rec:['','Rec.','Запись','Ձայն.'],
    crm:['','CRM','CRM','CRM'],
    action:['','Action','Редактировать','Փոփոխել'],
    add:['','Add SIP num.','Добавить SIP номер','Ավելացնել'],
    edit:['','SIP num.','SIP номер','SIP համար'],
    add_btn:['','Add','Добавить','Ավելացնել'],
    num:['','SIP num.:','SIP телефон:','SIP հեռախոս'],
    pass:['','Password:','Пароль:','Գաղտնաբառ'],
    group:['','Group Call:','Группавой вызов:','Խմբային զանգ'],
    call_rec:['','Call record:','Запись звонков (отметьте количество дней):','Ձայնագրել զանգերը:'],
    reenter:['','Reenter password:','Повторите пароль:','Կրկնեք գաղտնաբառը:'],
    mobile:['','Mobile','Моб','բջջ'],
    fix_num:['','Fix num','Гор','Քաղ'],
    international:['','International','Междунар','Միջ'],
    payed:['','Payed','Платные','Վճար'],
    history: ['','History and record','История и Запись звонков','Պատմություն և ձայնագրություն'],
    history1: ['','History','История','Պատմություն'],
    sipHistory: ['','SIP number history only','Только история SIP-номеров','Միայն SIP համարի պատմություն'],
    sipHistory1: ['','Only SIP number history with recording','Только история SIP-номеров с записью','Միայն SIP համարի պատմություն ձայնագրությամբ'],
    ip: ['','Registration ip client. Ip address','Registration ip client. Ip address','Registration ip client. Ip address'],
}

export const included = {
    access:['','Out access','Управл. исходящими','Թ. ուղղ.'],
    mob:['','MobAPP','MobAPP','MobAPP'],
    private:['','Private mob. num.','Внутренние мобильные номера','Ներառված հեռախոսահամարներ'],
    phone:['','Phone','Номер телефона','Համար'],
    mobile: ['','Mobile APP','Mobile APP','Mobile APP'],
    group: ['','Group','Группа','Խումբ']
}
export const shortFunction = {
    phone:['','Phone','Номер телефона','Կարճ գործառույթների անվանում'],
    prev: ['','Mobile APP','Mobile APP','Կարճ գործառույթների նախկին անվանում '],
}

export const short = {
    short:['','Short num','Короткий номер','կարճ ենթահամարներ/խմբեր'],
    list:['','List','Список','Ցանկ'],
    no_answer:['','No answer','Отсутствие ответа','Չպ. դեպք'],
    phone:['','Phone','Номер','Համար'],
    mobile: ['','Mobile APP'],
    group: ['','Group','Группа','Խումբ'],
    private: ['','Private num.','Короткий номер','Ներքին համար'],
    week: ['','Week days(start - end)','Дни недели (начало - конец)','Շաբ. օրեր (սկիզբ - վերջ)'],
    time: ['','Time (start - end)',' Часы (начало - конец)',' Ժամեր (սկիզբ – վերջ)'],
    virtual: ['','Virtual num.','Короткий номер','Վիրտուալ համար'],
    sec: ['','Sec.','Время, в секундах','վարկյան'],
    wait: ['','Call waiting','Ожидание вызова','Միաժամանակ զանգի Հն․'],
}

export const ivr = {
    ivr:['','IVR','Голосовое меню (IVR)','Ձայնային մենյու (IVR)'],
    name:['','Name','Название','Անվանում'],
    private:['','Private num.','Внутренний номер','Վիրտուալ համար'],
    file:['','File','Файл','Ֆայլ'],
}

export const did = {
    did:['','DID num.','DID Номера','DID հեռախոսահամարներ'],
    line:['','Line','Линия','Գիծ'],
    no_line:['','No Line','Отбой','no line'],
    used_lines:['','Used lines','Используемые линии','Օգտագործվող գծեր'],
    calc:['','Calculator','Счётчик','Հաշվիչ'],
    active:['','Active','Онлайн','Առցանց'],
    date:['','In - Out Date','Вход.-Исход./час','Մուտք-ելք ժամ'],
    operator:['','Choose operator','Выберите оператора','Ընտրեք օպերատորին'],
    profile:['','Choose profile','Выберите профиль','Ընտրեք պրոֆիլը'],
    sip:['','Sip operator','SIP оператор','SIP պրովայդերներ']
}

export const global = {
    count:['','Count','Количество','Քանակ '],
    edit:['','Save changes','Сохранить изменения','Պահպանել'],
    delete:['','Delete','Удалить','Ջնջել'],
    deleteAll:['','Delete All','Удалить все','Ջնջել բոլորը'],
    add:['','Add','Добавить','Ավելացնել'],
    date:['','Date','Дата','Ամսաթիվ'],
    ip:['','IP','IP','IP'],
    success:['','Changes are successfully made.','Изменения успешно внесены','Փոփոխությունները հաջողությամբ կատարված են'],
    create:['','Created successfully','Создано успешно','Հաջողությամբ ստեղծված է'],
    deleted:['','Successfully deleted','Успешно удалено','Հաջողությամբ ջնջվել է']
}

export const moneyT = {
    act:['','Count','Количество','Քանակ '],
    name:['','Save changes','Описание','Նկարագրություն'],
    money:['','dr','др','amd'],
    history:['','View price change during last 5 updates','Просмотр изменения цен за последние 5 обновлений','Դիտեք գնի փոփոխությունը վերջին 5 թարմացման ընթացքում'],
    OP:['','Short Короткие номера','Короткие номера','Կարճ ենթահամարներ'],
    IVR:['','Voice Menu (IVR)','Голосовое меню (IVR)','Ձայնային մենյու (IVR)'],
    REG:['','Count Lines','Количество Линии','Գծերի քանակ'],
    REC:['','Call recording','Запись разговоров','Զանգերի ձայնագ․'],
    PHONE:['','Included mob. numbers','Внутренние моб. номера','Ներառված հեռ.'],
    SIP:['','SIP Numbers','SIP Номера','SIP համարներ'],
    CRM:['','Integration','Интеграция','Ինտեգրացիա'],
    RECDAY:['','Call recording a day','Запись разговоров за день','Մեկ օրվա Զանգերի ձայնագ․'],
    MOBAPP:['','MOBAPP','MOBAPP','MOBAPP'],
    MOB:['','MOB','MOB','MOB'],
    CRMSTP:['','CRMSTP','CRMSTP','CRMSTP'],
    AZANG:['','AZANG','AZANG','AZANG'],

}

export const historyHeader = {
    phone:['','Ext Number','Номер телефона','Հեռախոսահամար.: '],
    phone1:['','Number','Номер телефона','Հեռախոսահամար '],
    start:['','Start','Начало','Սկիզբ'],
    end:['','End','Конец','Վերջ'],
    calls:['','Calls','Вызовы','Զանգեր'],
    virtual:['','Output line','Исходящая линия','վիրտուալ համար'],
    status:['','Status','Статус','կարգավիճ'],
    sip:['','SIP Numbers','SIP Номера','SIP համարներ'],
    included:['','Included mob. numbers','Внутренние моб. номера','Ներառված հեռ.'],
    call_stop:['','incoming line:','№ принимающий вызов:','ընդհատված զանգ:']
}

export const crm = {
    crm:['','CRM integration','Интеграция с CRM','CRM ինտեգրում']
}


export const history = [
    ['','NN','NN','NN'],
    ['','(start - end)','(начало - конец)','(սկիզբ – վերջ)'],
    ['','Caller num.','Номер звонящего','զանգահ'],
    ['','Duration','Длительность','տևող'],
    ['','Ext Num','Линия','ելքային գիծ'],
    ['','In/out line','Линия вход.-исход','մուտք-ելք'],
    ['','Short number','Короткие номер','Կարճ ենթահամար'],
    ['','Status','Статус','կարգավիճ'],
    ['','Receiver num.','Номер принимающего','ընդունող համար'],
    ['','Record','Запись','ձայն'],
]
export const historyMedia = [
    ['','Record','Запись','ձայն'],
    ['','(start - end)','(начало - конец)','(սկիզբ – վերջ)'],
    ['','Caller num.','Номер звонящего','զանգահ'],
    ['','Duration','Длительность','տևող'],
    ['','In/out line','Линия вход.-исход','մուտք-ելք'],
    ['','Status','Статус','կարգավիճ'],
]

export const file = {
    file:['','Files','Работа с файлами','Աշխատանք ֆայլերի հետ'],
    voice:['','Voice Text','Voice Text','Ձայնային տեքստ'],
    yandex:['','Yandex Speechkit','Yandex Speechkit','Yandex Speechkit'],
    name:['','Name','Название','Անվանում'],
    name1:['','Enter name','Введите название загружаемого файла','Մուտքագրեք անվանում բեռնվող ֆայլի համար'],
    choose:['','Choose file','Выберите файл','Ընտրեք ֆայլ'],
    key:['','The file must be in MP3 format and not more than 5 MB.','Файл должен быть в формате MP3 и не более 5Мб.','Ֆայլը պետք է լինի MP3 ֆորմատի և չգերազանցի 5Mb'],
    fileUpload:['','Upload date','Дата загрузки','բեռնման ամսաթիվ'],
    volume:['','Volume','Объем','ծավալ'],
    code:['','System code','Системный код','համակարգի կոդ'],
    hold:['','Music hold','Мелодия ожидания','music hold'],
    myVT:['','My voice text','Мой голосовой текст','Իմ ձայնային տեքստը'],
    lang:['','Choose your language','Выберите ваш язык','Ընտրեք ձեր լեզուն'],
    myVoice:['','Choose your voice','Выберите свой голос','Ընտրեք ձեր ձայնը'],
    myYandex:['','File name','Имя файла','Ֆայլի անունը'],
    arm:['','Armenian','Армянский','հայերեն'],
    eng:['','English','Английский','Անգլերեն'],
    ru:['','Russian','Русский','ռուսերեն'],

}

export const forward = {
    forward:['','Forward','Переадресация','Վերահասցեավորումներ'],
    call_rout:['','Call route','Перенаправление звонков','Զանգերի ուղորդում'],
    prefix:['','Prefix','Определитель','Որոշիչ'],
    ext:['','Select ext. num.','Выберите выходную строку','Ընտրեք ելքային գիծ'],
}

export const auto = {
    success:['','Successfully','Состоявшийся','Հաջողված'],
    fail:['','Failed','Несостоявшийся','Անհաջող'],
    current:['','Current','Текущий','Ընթացիկ'],
    line:['','Number of lines','Количество линий','Գծերի քանակ'],
    type:['','Type','Тип','Տիպ'],
    name:['','Names','Названия','Անվանում'],
    status:['','Status','Статус','Կարգ'],
    ok:['','ok-nok','ok-nok','ok-nok'],
    attempt:['','Attempt','Попытка','Փորձ'],
    num:['','Private num.','Короткий номер','Ներքին համար'],
    phone:['','Phone num.','Номер','Համար'],
}

export const weekDays = [
    ['', "Sun",'Вс','Կի'],
    ['',"Mon",'Пн','Եր' ],
    ['',"Tue",'Пн','Եք'],
    ['',"Wed",'Ср','Չր'],
    ['',"Thu",'Чт','Հն'],
    ['',"Fri", 'Пт','Ուր'],
    ['',"Sat",'Сб','Շբ'],
]

export const profile = {
    username:['','Username:','Имя пользователя:','Օգտագործողի անունը'],
    profile:['','User data','Данные пользователя','Օգտատերի տվյալները'],
    num:['','Contact num.','Контактный тел.','Կոնտակտային հեռ։'],
    mail:['','Email','Эл. почта','Էլեկտրոնային հասցե:'],
    name:['','Company name','Название организации','Կազմակերպ. անվանում:'],
    login:['','Login','Логин','մուտքանուն:'],
    pass:['','Password','Пароль','Գաղտնաբառ:'],
    repass:['','Reenter password','Повторить пароль','Կրկնել գաղտնաբառը:'],
    manager:['','Manager List','Список менеджеров','Մենեջերի Ցանկ'],
    manager_contact:['',"Manager's contact phone number:",'Контактный телефон менеджера:','Մենեջերի Կոնտակտային հեռ.:'],
    manager_workDay:['',"Manager's working days.",'Рабочие дни менеджера..','Մենեջերի աշխատանքային օրեր:'],
    manager_workHour:['',"Manager's working hours.",'График работы менеджера.','Մենեջերի աշխատանքային ժամերը:'],
    m_name:['','Name of the manager.','Имя менеджера.','Մենեջերի անուն:'],
    m_email:['','Email address of the manager.','Адрес электронной почты менеджера.','Մենեջերի Էլեկտրոնային հասցե:'],
}

export const lang = {
    1:"en-AU",
    2:'ru',
    3:'hy'
}
export const login = {
    title:['','Login','Логин','Լոգին'],
    titleR:['','Registration','Регистрация','Գրանցում'],
    username:['','Your Username or Phone','Ваше имя пользователя или номер','Ձեր օգտանունը կամ հեռախոսահամարը'],
    pass:['','Your Password','Ваш пароль','Ձեր գաղտնաբառը'],
    contact:['','Contact support','Обратитесь в службу поддержки','Կապվեք հաճախորդների սպասարկման հետ'],
    tel:['','Tel:','Тел:','Հեռ:'],
}

export const urs = {

}

export const history1 = {
    topBar:[
        {id:1,type:'external',translate:['','External calls','Внешние звонки','Արտաքին զանգեր']},
        {id:2,type:'inner',translate:['','Internal calls','Внутренние звонки','Ներքին զանգեր']},
    ],
    dayFilter:[
        {id:1,value:'today',translate:['','Today','Сегодня','Այսօր']},
        {id:2,value:'yesterday',translate:['','Yesterday','Вчера','Երեկ']},
        {id:3,value:'this_week',translate:['','This week','Текущая неделя','Այս շաբաթ']},
        {id:4,value:'last_week',translate:['','Last week','Прошлая неделя','Անցած շաբաթ']},
        {id:5,value:'current_month',translate:['','Current month','Текущий месяц','Ընթացիկ ամիս']},
        {id:6,value:'last_month',translate:['','Last month','Прошлый месяц','Անցած ամիս']},
        {id:7,value:'arbitrary_period',translate:['','Arbitrary period','Произвольный период','Ազատ ժամանակաշրջան']},
    ],
    callType:[
        {id:1,type:'in',color:'rgb(118, 209, 44)',
            value:'Incoming Call',
            path:'M4.293 4.293a1 1 0 0 1 1.414 0L13 11.586V7a1 1 0 1 1 2 0v5a3 3 0 0 1-3 3H7a1 1 0 1 1 0-2h4.586L4.293 5.707a1 1 0 0 1 0-1.414zM18 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4z',
            translate:['','Incoming calls','Входящие','Մուտքային զանգեր']},
        {id:2,type:'missed',color:'rgb(255, 101, 118)',
            value:'Missed',
            path:'M5.707 4.293a1 1 0 0 0-1.414 1.414L11.586 13H7a1 1 0 1 0 0 2h5a3 3 0 0 0 3-3V7a1 1 0 1 0-2 0v4.586L5.707 4.293zM15.293 15.293a1 1 0 0 1 1.414 0l.793.793.793-.793a1 1 0 0 1 1.414 1.414l-.793.793.793.793a1 1 0 0 1-1.414 1.414l-.793-.793-.793.793a1 1 0 0 1-1.414-1.414l.793-.793-.793-.793a1 1 0 0 1 0-1.414z',
            translate:['','Missed','Пропущенные','Բաց թողած']},
        {id:3,type:'out',color:'rgb(80, 176, 245)',
            value: 'Out Call',
            path:'M13.293 14.707a1 1 0 0 0 1.414-1.414L7.414 6H12a1 1 0 1 0 0-2H7a3 3 0 0 0-3 3v5a1 1 0 1 0 2 0V7.414l7.293 7.293zM20 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0z',
            translate:['','Outgoing','Исходящие','Ելքային']},
    ]
}
